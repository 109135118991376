<template lang="pug">
div
  Header(:documentStatus="{ status: documentData?.status, time: documentData?.time_check, verification_number: documentData?.verification_number }")
  div.mb-25.mt-20
    div.document-content-title {{ documentData?.type_document?.[langFields] }}
    div(v-if="isServiceBook").document-content-title № {{ documentData?.number_document }}
  div.d-flex.flex-column
    div.mb-20
      div(v-if="documentData?.name_ukr").document-content-column-title {{ $t('documentInfo.nameInUkr') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.name_ukr }}
      div(v-if="documentData?.name_eng").document-content-column-title {{ $t('documentInfo.nameInEng') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.name_eng }}
      div(v-if="documentData?.date_birth").document-content-column-title {{ $t('documentInfo.dateOfBirth') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_birth }}
      div(v-if="documentData?.sex?.[langFields]").document-content-column-title {{ $t('documentInfo.sex') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.sex?.[langFields] }}
      div(v-if="documentData?.citizenship?.[langFields]").document-content-column-title {{ $t('documentInfo.citizenship') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.citizenship?.[langFields] }}
      div(v-if="isMedical")
        div(v-if="documentData?.number_document").document-content-column-title {{ $t('documentInfo.documentNumber') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.number_document }}
        div(v-if="documentData?.date_start").document-content-column-title {{ $t('documentInfo.dateOfIssueDocument') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_start }}
        div(v-if="documentData?.date_end").document-content-column-title {{ $t('documentInfo.expiryDate') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_end }}
        div(v-if="documentData?.limitation?.[langFields]").document-content-column-title {{ $t('documentInfo.limitation') }}:
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.limitation?.[langFields] }}
        div(v-if="documentData?.position?.[langFields]").document-content-column-title {{ $t('documentInfo.position') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.position?.[langFields] }}
      div(v-if="isServiceBook")
        div(v-if="documentData?.auth_official?.[langFields]").document-content-column-title {{ $t('documentInfo.agent') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.auth_official?.[langFields] }}
        div(v-if="documentData?.branch_office?.[langFields]").document-content-column-title {{ $t('documentInfo.placeOfIssue') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.branch_office?.[langFields] }}
        div(v-if="documentData?.date_issued").document-content-column-title {{ $t('documentInfo.dateOfIssueDocument') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_issued }}
        div(v-if="documentData?.blank_strict_report").document-content-column-title {{ $t('documentInfo.formNumber') }}
          div.fw-500.black--text.align-self-end.text-right {{ documentData?.blank_strict_report }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServiceBookOrMedical',
  props: { documentData: { type: Object, default: () => ({}) } },
  components: { Header: () => import('@/components/layouts/DocumentHeader') },
  computed: { 
    ...mapState({ langFields: state => (state.app.lang === 'en') ? 'eng' : 'ukr' }),
    isServiceBook() { return this.documentData?.service_type_document === 'service_book' },
    isMedical() { return this.documentData?.service_type_document === 'medical' }
  }
}
</script>